<template>
  <!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        일산연수원 운동장 이용안내
      </div>
      <!-- //header-title -->
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">

    <!-- main-content -->
    <div class="main-content main-component">

      <div class="educourse-view-container course-cancel">
        <div class="view-contents">
          <section class="content-section">
            <header class="section-header header-divider">
              <h3 class="title">이용안내</h3>
            </header>
            <div class="section-content">
              <ul class="text-list">
                <li class="text font-body14" data-bullet="1.">신청은 3분이내로 완료해 주십시오. 3분안에 신청이 완료되지 않을 경우 자동 취소 처리 됩니다.</li>
                <li class="text font-body14" data-bullet="2.">이용예약은 사용월 전월 둘째 영업일 17시부터 신청 가능합니다.</li>
                <li class="text font-body14" data-bullet="3.">신청자(정 또는 부)는 이용일자 기준으로 월 1회만 이용가능 합니다.</li>
                <li class="text font-body14" data-bullet="4.">당행동호회의 당행직원에 한하여 이용 가능합니다.</li>
                <li class="text font-body14" data-bullet="5.">신청직원 2명 필수 등록 및 참석 상주하여야 하며 이용직원을 인솔 관리 감독하셔야 합니다.</li>
                <li class="text font-body14" data-bullet="6.">개인별 월1회(2시간) 이용 가능합니다.</li>
                <li class="text font-body14" data-bullet="7.">2022.9.21일자 공지사항 등재내용 (운동장 운영 및 패널티)을 확인하시기 바랍니다.</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
      <div class="page-buttons"><button class="kb-btn kb-btn-primary" @click="nextStep">신청</button></div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>
<script>
import {computed, ref} from 'vue';
import {useStore} from "vuex";

import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileSportsApplyStep1',
  components: {

  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const session = computed(() => store.state.auth.session);

    const reserveVenue = ref('체육시설');

    const nextStep = () => {
      router.push('/daily/apply/sports/step2');
    }

    const goBack = () => {
      router.go(-1);
    }

    return {
      reserveVenue,
      session,
      nextStep,
      goBack
    };
  },
};
</script>
